// src/components/CreateNewControllerWizardV2/components/shared/WidgetPreview.tsx

import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { ComputerIcon } from 'lucide-react';
import { appConfig } from '../../../app-config';
import { MobileIcon } from '../../../assets/icons';
import { useWizard } from '../context/WizardContext';
import { useCustomerSettings } from '../../../contexts/CustomerSettingsContext';

interface PreviewContentProps {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  device: 'desktop' | 'mobile';
  isSaving: boolean;
  dimensions: {
    width: string;
    height: string;
    maxWidth?: string;
    margin?: string;
    paddingBottom?: string;
  };
  fullscreenMode: boolean;
}

const PreviewContent: React.FC<PreviewContentProps> = ({
  iframeRef,
  device,
  isSaving,
  dimensions,
  fullscreenMode,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: dimensions.width,
        height: dimensions.height,
        maxWidth: fullscreenMode ? dimensions.maxWidth : 'none',
        margin: fullscreenMode ? dimensions.margin : 'none',
        paddingBottom: fullscreenMode ? 'none' : dimensions.paddingBottom,
        aspectRatio: fullscreenMode ? '9/16' : 'auto', // For vertical video
      }}
    >
      {isSaving && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={24} sx={{ mb: 1 }} />
            <Typography display="block" variant="caption">
              Updating preview...
            </Typography>
          </Box>
        </Box>
      )}

      <iframe
        ref={iframeRef}
        style={{
          // eslint-disable-next-line no-nested-ternary
          position: fullscreenMode ? 'relative' : device === 'desktop' ? 'absolute' : 'relative',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: theme.shape.borderRadius,
          filter: isSaving ? 'grayscale(30%)' : 'none',
          transition: 'filter 0.2s ease',
        }}
        title="Widget Preview"
      />
    </Box>
  );
};

interface WidgetPreviewProps {
  title?: string;
  fullscreenMode?: boolean;
}

export const WidgetPreview: React.FC<WidgetPreviewProps> = ({
  title = 'Widget Preview',
  fullscreenMode = false,
}) => {
  const [device, setDevice] = useState<'desktop' | 'mobile'>(fullscreenMode ? 'mobile' : 'desktop');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const theme = useTheme();
  const { state } = useWizard();
  const { controllerId, previewKey, isSaving } = state;
  const customerSettingsMethods = useCustomerSettings();

  const dimensions = useMemo(() => {
    if (fullscreenMode) {
      return {
        width: '100%', // Take full width
        height: '100%',
        maxWidth: '80vh', // This will maintain aspect ratio based on viewport height
        margin: '0 auto', // Center horizontally
      };
    }

    return device === 'mobile'
      ? { width: '375px', height: '667px' }
      : { width: '100%', height: '0', paddingBottom: '56.25%' };
  }, [device, fullscreenMode]);

  const clientId = customerSettingsMethods?.customer?.clientId;

  const updateIframeContent = () => {
    if (controllerId && iframeRef.current) {
      const iframeContent = fullscreenMode
        ? `
          <!DOCTYPE html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <style>
                body { 
                  margin: 0; 
                  padding: 0;
                  background: transparent;
                }
              </style>
            </head>
            <body>
              <script id="closer2-widget" 
                src="${appConfig.widgetUrl}" 
                data-c2-controller-id="${controllerId}"
                data-c2-client-id="${clientId}"
                data-c2-fullscreen-mode="${fullscreenMode}" 
                async>
              </script>
            </body>
          </html>
        `
        : `
          <!DOCTYPE html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" rel="stylesheet">
              <style>
                body { 
                  font-family: Arial, sans-serif; 
                  margin: 0; 
                  padding: 0;
                  background-color: #f8f9fa;
                }
                
                .skeleton {
                  background: linear-gradient(
                    90deg,
                    #f0f0f0 25%,
                    #e0e0e0 50%,
                    #f0f0f0 75%
                  );
                  background-size: 200% 100%;
                  animation: false;
                }
                
                .header {
                  background-color: white;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }

                .content {
                  max-width: 1200px;
                  margin: 0 auto;
                  padding: 20px;
                }

                .hero-section {
                  background-color: white;
                  border-radius: 8px;
                  margin-bottom: 24px;
                  padding: 32px;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }

                .card-grid {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                  gap: 24px;
                }

                .card {
                  background-color: white;
                  border-radius: 8px;
                  overflow: hidden;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }
              </style>
            </head>
            <body>
              <!-- Header -->
              <header class="header p-4">
                <div class="content flex items-center justify-between">
                  <div class="flex items-center gap-8">
                    <div class="skeleton w-32 h-8 rounded"></div>
                    <div class="hidden md:flex gap-6">
                      <div class="skeleton w-20 h-4 rounded"></div>
                      <div class="skeleton w-20 h-4 rounded"></div>
                      <div class="skeleton w-20 h-4 rounded"></div>
                    </div>
                  </div>
                  <div class="skeleton w-64 h-10 rounded-full"></div>
                </div>
              </header>

              <main class="content">
                <section class="hero-section">
                  <div class="skeleton w-3/4 h-12 rounded mb-6"></div>
                  <div class="skeleton w-full h-64 rounded mb-6"></div>
                  <div class="flex gap-4">
                    <div class="skeleton w-32 h-10 rounded"></div>
                    <div class="skeleton w-32 h-10 rounded"></div>
                  </div>
                </section>

                <div class="card-grid">
                  ${Array(6)
                    .fill(0)
                    .map(
                      () => `
                    <div class="card">
                      <div class="skeleton w-full h-48"></div>
                      <div class="p-4">
                        <div class="skeleton w-3/4 h-6 rounded mb-4"></div>
                        <div class="skeleton w-full h-4 rounded mb-2"></div>
                        <div class="skeleton w-2/3 h-4 rounded"></div>
                      </div>
                    </div>
                  `,
                    )
                    .join('')}
                </div>
              </main>

              <script id="closer2-widget" 
                src="${appConfig.widgetUrl}" 
                data-c2-controller-id="${controllerId}"
                data-c2-client-id="${clientId}"
                data-c2-fullscreen-mode="${fullscreenMode}" 
                async>
              </script>
            </body>
          </html>
        `;

      iframeRef.current.srcdoc = iframeContent;
    }
  };

  useEffect(() => {
    updateIframeContent();
  }, [controllerId, previewKey, device, fullscreenMode]);

  // For fullscreen mode
  if (fullscreenMode) {
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">{title}</Typography>
          {isSaving && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} />
              <Typography variant="caption">Updating...</Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            minHeight: 0,
            p: 2, // Add some padding around the preview
          }}
        >
          {controllerId ? (
            <PreviewContent
              device={device}
              dimensions={dimensions}
              iframeRef={iframeRef}
              isSaving={isSaving}
              fullscreenMode={fullscreenMode}
            />
          ) : (
            <Typography>Upload a video to see the preview.</Typography>
          )}
        </Box>
      </Box>
    );
  }

  // For regular mode
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6">{title}</Typography>
          {isSaving && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} />
              <Typography variant="caption">Updating...</Typography>
            </Box>
          )}
        </Box>
        <ToggleButtonGroup
          exclusive
          onChange={(_, newDevice) => {
            if (newDevice !== null) {
              setDevice(newDevice);
            }
          }}
          size="small"
          value={device}
        >
          <ToggleButton disabled={isSaving} value="desktop">
            <ComputerIcon />
          </ToggleButton>
          <ToggleButton disabled={isSaving} value="mobile">
            <MobileIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.grey[100],
          border: '1px solid',
          borderColor: theme.palette.grey[300],
          borderRadius: 1,
          overflow: 'hidden',
          minHeight: 0,
        }}
      >
        {controllerId ? (
          <PreviewContent
            device={device}
            dimensions={dimensions}
            fullscreenMode={fullscreenMode}
            iframeRef={iframeRef}
            isSaving={isSaving}
          />
        ) : (
          <Typography>Upload a video to see the preview.</Typography>
        )}
      </Box>
    </Box>
  );
};
