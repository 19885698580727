// src/components/CreateNewControllerWizardV2/context/WizardContext.tsx

import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { WizardAction, WizardState } from '../types';

const defaultBorder = {
  enabled: true,
  gradientColors: ['#00df81', '#653ffe'] as [string, string],
};

const defaultBubble = {
  enabled: true,
  text: 'get a discount!',
  design: {
    backgroundColor: '#653ffe',
    textColor: '#ffffff',
  },
};

const initialState: WizardState = {
  currentStep: 0,
  videoData: null,
  styleConfig: {
    widgetSize: 'M',
    shape: 'circle',
    border: defaultBorder,
    positioning: {
      position: 'bottomLeft',
      bottomLeft: {
        marginBottom: 20,
        marginLeft: 20,
      },
      bottomRight: {
        marginBottom: 20,
        marginRight: 20,
      },
    },
    addOns: {
      bubble: defaultBubble,
    },
  },
  actionConfig: {
    name: '',
    isAllTime: true,
    action: {
      buttonLabel: 'Leave us your email',
      instructionsText: 'Please leave us your email to get a discount',
      disclaimerText: 'In order to continue accept Terms and Conditions',
    },
  },
  configurationConfig: {
    name: '',
    isAllTime: true,
    scheduling: {
      validFrom: null,
      validUntil: null,
    },
    action: {
      buttonLabel: 'Leave us your email',
      instructionsText: 'Please leave us your email to get a discount',
      disclaimerText: 'In order to continue accept Terms and Conditions',
    },
  },
  controllerId: null,
  error: null,
  isSaving: false,
  previewKey: 0,
  activeTab: 0,
  forms: {},
};

const wizardReducer = (state: WizardState, action: WizardAction): WizardState => {
  switch (action.type) {
    case 'SET_STEP':
      return { ...state, currentStep: action.payload };
    case 'SET_VIDEO':
      return { ...state, videoData: action.payload };
    case 'UPDATE_STYLE':
      return {
        ...state,
        styleConfig: { ...state.styleConfig, ...action.payload },
        previewKey: state.previewKey + 1,
      };
    case 'UPDATE_ACTION':
      return {
        ...state,
        actionConfig: { ...state.actionConfig, ...action.payload },
        previewKey: state.previewKey + 1,
      };
    case 'SET_CONTROLLER_ID':
      return { ...state, controllerId: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SAVING':
      return { ...state, isSaving: action.payload };
    case 'TRIGGER_PREVIEW_REFRESH':
      return { ...state, previewKey: state.previewKey + 1 };
    case 'SET_FORM_HANDLERS':
      return {
        ...state,
        forms: {
          ...state.forms,
          ...action.payload,
        },
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'UPDATE_CONFIGURATION':
      return {
        ...state,
        configurationConfig: {
          ...state.configurationConfig,
          ...action.payload,
        },
        previewKey: state.previewKey + 1,
      };
    default:
      return state;
  }
};

const WizardContext = createContext<{
  state: WizardState;
  dispatch: React.Dispatch<WizardAction>;
} | null>(null);

export const WizardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(wizardReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state]);

  return <WizardContext.Provider value={contextValue}>{children}</WizardContext.Provider>;
};

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context;
};
