// src/components/CreateNewControllerWizardV2/steps/VideoUploadStep/components/VideoSelection.tsx

import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Paper, Box, Typography } from '@mui/material';
import { useAssetsQuery } from '../../../../../hooks/api/assets/assets.generated';
import { LoadingSpinner } from '../../../../Utils';
import { VideoSelectionProps } from '../types';
import { TestIds } from '../../../../../test-utils';
import { useWizard } from '../../../context/WizardContext';

export const VideoSelection: React.FC<VideoSelectionProps> = ({
  onSelect,
}: VideoSelectionProps) => {
  const { dispatch } = useWizard();
  const { data: assetsData, loading } = useAssetsQuery();
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Store the selection handler in the wizard context
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        selection: {
          handleSelect: onSelect,
          selectedAssetId,
          isSubmitting,
        },
      },
    });
  }, [selectedAssetId, isSubmitting]);

  if (loading) return <LoadingSpinner />;

  const hasAssets = assetsData?.assets?.assets && assetsData.assets.assets.length > 0;

  useEffect(() => {
    if (hasAssets) {
      setSelectedAssetId(assetsData.assets.assets[0].id);
    }
  }, [hasAssets]);

  if (!hasAssets) {
    return (
      <Typography color="text.secondary">
        No existing videos found. Please upload a new video.
      </Typography>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <FormControl fullWidth>
        <InputLabel>Select Video</InputLabel>
        <Select
          data-testid={TestIds.UPLOAD_FORM.EXISTING_VIDEO_SELECT}
          onChange={(e) => setSelectedAssetId(e.target.value)}
          value={selectedAssetId}
        >
          {assetsData.assets.assets.map((asset) => (
            <MenuItem key={asset.id} value={asset.id}>
              {asset.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};
