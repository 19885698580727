import React, { useRef, useState, MouseEvent, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
  Typography,
} from '@mui/material';
import { useAssetUpload } from '../../hooks/useAssetUpload';
import { appConfig } from '../../app-config';

const MAX_FILE_SIZE = appConfig.video.maxFileSize;
const MAX_VIDEO_DURATION = appConfig.video.maxVideoDuration;

interface DragAndDropInputProps {
  onFileUploadComplete: (data: {
    name: string;
    fileId: string;
    resolution?: string;
    size?: number;
    duration?: number;
  }) => void;
  initialFile?: { name: string; fileId: string };
  label?: string;
  errors?: any;
  name?: string;
  disabled?: boolean;
  'data-testid'?: string;
}

const DragAndDropInput = forwardRef<HTMLInputElement, DragAndDropInputProps>(
  (
    { onFileUploadComplete, disabled, label, errors, name, initialFile, 'data-testid': testId },
    ref,
  ) => {
    const [file, setFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const fieldError = errors && (errors[name ?? '']?.fileId || errors[name ?? '']?.name);
    const { uploadAsset, uploading } = useAssetUpload();
    const isInitialFileValid =
      initialFile && (initialFile.name !== '' || initialFile.fileId !== '');
    const isFileLoaded = file || isInitialFileValid;
    const [validationError, setValidationError] = useState<string | null>(null);

    const validateFile = (
      uploadedFile: File,
    ): Promise<{
      isValid: boolean;
      details?: { resolution: string; size: number; duration: number };
    }> => {
      return new Promise((resolve) => {
        if (uploadedFile.size > MAX_FILE_SIZE) {
          setValidationError(`File size exceeds ${MAX_FILE_SIZE / (1024 * 1024)} MB limit.`); // validate size
          resolve({ isValid: false });
          return;
        }

        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          if (video.duration > MAX_VIDEO_DURATION) {
            setValidationError(`Video duration exceeds ${MAX_VIDEO_DURATION / 60} minutes limit.`); // validate duration
            resolve({ isValid: false });
          } else {
            const aspectRatio = video.videoWidth / video.videoHeight;
            if (aspectRatio < 0.5 || aspectRatio > 0.75) {
              setValidationError(
                'Video aspect ratio must be between 0.5 and 0.75 (vertical rectangle).',
              ); // validate aspect ratio
              resolve({ isValid: false });
            } else {
              const details = {
                resolution: `${video.videoWidth}x${video.videoHeight}`,
                size: uploadedFile.size,
                duration: video.duration,
              };
              resolve({ isValid: true, details });
            }
          }
        };

        video.onerror = () => {
          setValidationError('Error validating video file.');
          resolve({ isValid: false });
        };

        video.src = URL.createObjectURL(uploadedFile);
      });
    };

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles) => {
        if (!disabled && !uploading) {
          const droppedFile = acceptedFiles[0];
          const { isValid, details } = await validateFile(droppedFile);
          if (isValid) {
            setFile(droppedFile);
            await uploadAsset(droppedFile, (fileId: string, uploadedFileName: string) => {
              onFileUploadComplete({
                fileId,
                name: uploadedFileName,
                resolution: details?.resolution,
                size: details?.size,
                duration: details?.duration,
              });
            });
          }
        }
      },
      accept: {
        'video/*': ['.mp4', '.mov', '.wmv', '.avi'],
      },
      multiple: false,
    });

    const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setFile(null);
      onFileUploadComplete({ name: '', fileId: '' });
    };

    const handleUploadClick = () => {
      if (!disabled && !uploading) {
        inputRef.current?.click();
      }
    };

    const handleCloseSnackbar = () => {
      setValidationError(null);
    };

    return (
      <Box data-testid={`${testId}-container`}>
        <Typography pb={1}>{label}</Typography>
        <Box
          {...getRootProps()}
          data-testid={`${testId}-dropzone`}
          style={{
            pointerEvents: disabled || uploading ? 'none' : 'auto',
            border: fieldError ? '1px dashed #d32f2f' : '1px dashed #DBDBDB',
            padding: '20px',
            textAlign: 'center',
            maxWidth: '320px',
          }}
        >
          <input
            ref={inputRef}
            data-testid={`${testId}-input`}
            {...getInputProps()}
            disabled={uploading || disabled}
          />
          {uploading && (
            <CircularProgress
              color="secondary"
              data-testid={`${testId}-loading`}
              size={24}
              style={{
                position: 'relative',
                margin: 'auto',
              }}
            />
          )}
          {isFileLoaded ? (
            <>
              <Typography data-testid={`${testId}-filename`}>
                {file?.name ? file?.name : initialFile?.name}
              </Typography>
              <Button
                data-testid={`${testId}-delete-button`}
                disabled={uploading || disabled}
                onClick={(e) => handleDelete(e)}
                style={{ fontWeight: 700, color: '#D60000' }}
              >
                DELETE
              </Button>
            </>
          ) : (
            <div
              data-testid={`${testId}-upload-area`}
              style={{
                backgroundImage: `repeating-linear-gradient(
                45deg,
                transparent,
                transparent 10px,
                rgba(204, 204, 204, 0.3) 10px,
                rgba(204, 204, 204, 0.3) 20px
              )`,
              }}
            >
              <Typography>Drag and Drop</Typography>
              <Typography>or</Typography>
              <Button
                color="secondary"
                data-testid={`${testId}-upload-button`}
                disabled={uploading || disabled}
                onClick={handleUploadClick}
                style={{
                  fontWeight: 700,
                }}
                type="button"
                variant="contained"
              >
                UPLOAD
              </Button>
            </div>
          )}
        </Box>
        {fieldError && (
          <FormHelperText data-testid={`${testId}-error-message`} error>
            {fieldError.message}
          </FormHelperText>
        )}
        <Snackbar autoHideDuration={6000} onClose={handleCloseSnackbar} open={!!validationError}>
          <Alert
            data-testid={`${testId}-validation-error`}
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: '100%' }}
          >
            {validationError}
          </Alert>
        </Snackbar>
      </Box>
    );
  },
);

export default DragAndDropInput;
