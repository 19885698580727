// src/components/CreateNewControllerWizardV2/steps/StyleStep/index.tsx

import React from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { BorderConfig } from './components/BorderConfig';
import { BubbleConfig } from './components/BubbleConfig';
import { PositionConfig } from './components/PositionConfig';
import { WidgetSizeConfig } from './components/WidgetSizeConfig';
import { WidgetPreview } from '../../shared/WidgetPreview';

const StyleStep: React.FC = () => {
  return (
    <Grid container spacing={3}>
      {/* Form Column */}
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          {/* Basic Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <WidgetSizeConfig />
          </Paper>

          {/* Border Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BorderConfig />
          </Paper>

          {/* Position Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <PositionConfig />
          </Paper>

          {/* Bubble Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BubbleConfig />
          </Paper>
        </Stack>
      </Grid>

      {/* Preview Column */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StyleStep;
