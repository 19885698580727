// src/components/CreateNewControllerWizardV2/steps/VideoUploadStep/index.tsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, Tabs, Tab, Alert } from '@mui/material';
import { useWizard } from '../../context/WizardContext';
import { useCreateControllerMutation } from '../../../../hooks/api/createController/createController.generated';
import { VideoData } from '../../types';
import { useAssetsQuery } from '../../../../hooks/api/assets/assets.generated';
import { VideoSelection } from './components/VideoSelection';
import { VideoUploadForm } from './components/VideoUploadForm';

const VideoUploadStep: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useWizard();
  const [createController] = useCreateControllerMutation();
  const { data: assetsData } = useAssetsQuery();

  useEffect(() => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: tabValue,
    });
  }, [tabValue]);

  const handleError = (error: Error) => {
    dispatch({ type: 'SET_ERROR', payload: error });
  };

  const handleCreateController = async (videoData: VideoData) => {
    setIsSubmitting(true);
    try {
      const result = await createController({
        variables: {
          input: {
            name: videoData.name,
            isActive: true,
            initialStageId: '0',
            stages: [
              {
                id: '0',
                name: 'Initial Stage',
                content: {
                  id: videoData.fileId,
                  type: 'video',
                  videoAssetId: videoData.fileId,
                  videoAssetName: videoData.name,
                  videoAssetUrl: videoData.fileId,
                },
                actions: [
                  {
                    type: 'emailCollection',
                    isFinal: true,
                    closeWidget: true,
                    order: 0,
                    label: 'Leave us your email',
                    emailCollection: {
                      instructionsText: 'Please leave us your email to get a discount',
                      disclaimerText: 'In order to continue accept Terms and Conditions',
                      disclaimerUrl: 'https://www.closer2.io/terms-and-conditions',
                    },
                  },
                ],
              },
            ],
            layout: {
              type: 'widget',
              widget: {
                design: {
                  minified: {
                    widgetSize: 'M',
                    shape: 'circle',
                    caption: {
                      text: videoData.name,
                    },
                    border: {
                      enabled: true,
                      gradientColors: ['#00df81', '#653ffe'],
                    },
                    positioning: {
                      position: 'bottomLeft',
                      bottomLeft: {
                        marginBottom: 20,
                        marginLeft: 20,
                      },
                      bottomRight: {
                        marginBottom: 20,
                        marginRight: 20,
                      },
                    },
                    addOns: {
                      bubble: {
                        enabled: true,
                        text: 'get a discount!',
                        design: {
                          backgroundColor: '#653ffe',
                          textColor: '#ffffff',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

      if (result.data?.createController) {
        const controllerData = result.data.createController;
        dispatch({ type: 'SET_VIDEO', payload: videoData });
        dispatch({ type: 'SET_CONTROLLER_ID', payload: controllerData.id });
        dispatch({ type: 'SET_STEP', payload: 1 });
      } else {
        throw new Error('Failed to create controller');
      }
    } catch (error) {
      handleError(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExistingVideoSelect = async (assetId: string) => {
    const selectedAsset = assetsData?.assets.assets.find((asset) => asset.id === assetId);
    if (selectedAsset) {
      const videoData: VideoData = {
        name: selectedAsset.name,
        fileId: selectedAsset.videoAsset.fileId,
        resolution: selectedAsset.videoAsset.resolution ?? undefined,
        size: selectedAsset.videoAsset.size ?? undefined,
        duration: selectedAsset.videoAsset.duration ?? undefined,
      };
      await handleCreateController(videoData);
    }
  };

  return (
    <Box>
      <Typography fontSize={24} fontWeight={700} pb={4}>
        Choose a video
      </Typography>

      <Tabs onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 3 }} value={tabValue}>
        <Tab label="Upload New" />
        <Tab disabled={!assetsData?.assets?.assets?.length} label="Choose Existing" />
      </Tabs>

      <Box sx={{ mb: 4 }}>
        {' '}
        {/* Add bottom margin to ensure content doesn't get hidden behind fixed navigation */}
        {tabValue === 0 ? (
          <VideoUploadForm isSubmitting={isSubmitting} onUploadComplete={handleCreateController} />
        ) : (
          <VideoSelection onSelect={handleExistingVideoSelect} />
        )}
      </Box>
    </Box>
  );
};

export default VideoUploadStep;
