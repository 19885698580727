import React from 'react';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '../../../../Inputs';
import DragAndDropInput from '../../../../Common/DragAndDropInput';
import FileRequirements from '../../../../Common/FileRequirements';
import { TestIds } from '../../../../../test-utils';
import { VideoFormValues, VideoUploadFormProps } from '../types';
import { videoUploadSchema } from '../validation';
import { useWizard } from '../../../context/WizardContext';

export const VideoUploadForm: React.FC<VideoUploadFormProps> = ({
  onUploadComplete,
  isSubmitting,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VideoFormValues>({
    resolver: yupResolver(videoUploadSchema),
    mode: 'onChange',
  });

  const onSubmit = async (values: VideoFormValues) => {
    onUploadComplete({
      ...values.videoAsset,
      name: values.name,
    });
  };

  const { dispatch } = useWizard();

  return (
    <Paper elevation={3} sx={{ p: isLargeScreen ? 4 : 2 }}>
      <form data-testid={TestIds.UPLOAD_FORM.CONTAINER} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={3}>
              <TextInput
                data-testid={TestIds.UPLOAD_FORM.NAME_INPUT}
                {...register('name')}
                errors={errors}
                label="Video name"
                name="name"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 3,
                  alignItems: { md: 'flex-start' },
                }}
              >
                <Box sx={{ flex: { md: 1 } }}>
                  <DragAndDropInput
                    data-testid={TestIds.UPLOAD_FORM.VIDEO_UPLOAD}
                    {...register('videoAsset')}
                    errors={errors}
                    label="Add video file"
                    onFileUploadComplete={(value) => {
                      setValue('videoAsset', value);

                      if (value.fileId) {
                        dispatch({
                          type: 'SET_FORM_HANDLERS',
                          payload: {
                            upload: {
                              handleSubmit: handleSubmit(onSubmit),
                              isSubmitting,
                            },
                          },
                        });
                      } else {
                        dispatch({
                          type: 'SET_FORM_HANDLERS',
                          payload: {
                            upload: undefined,
                          },
                        });
                      }
                    }}
                  />
                </Box>
                <Box sx={{ flex: { md: '0 0 300px' } }}>
                  <FileRequirements />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
