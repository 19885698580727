// src/components/CreateNewControllerWizardV2/steps/StyleStep/components/BubbleConfig.tsx

import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { useWizard } from '../../../context/WizardContext';
import ColorPickerV2 from '../../../../Common/ColorPickerV2';
import { useStyleUpdates } from '../hooks/useStyleUpdates';

export const BubbleConfig: React.FC = () => {
  const { state } = useWizard();
  const { updateStyle } = useStyleUpdates();
  const bubble = state.styleConfig.addOns?.bubble;

  const handleBubbleChange = (changes: Partial<typeof bubble>) => {
    updateStyle({
      addOns: {
        bubble: {
          enabled: bubble?.enabled ?? true,
          text: bubble?.text ?? 'get a discount!',
          design: {
            backgroundColor: bubble?.design.backgroundColor ?? '#653ffe',
            textColor: bubble?.design.textColor ?? '#ffffff',
            ...bubble?.design,
          },
          ...changes,
        },
      },
    });
  };

  if (!bubble) return null;

  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Note bubble
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={bubble.enabled}
            onChange={(e) => handleBubbleChange({ enabled: e.target.checked })}
          />
        }
        label="Enable bubble with your note"
      />

      {bubble.enabled && (
        <Box mt={2}>
          <TextField
            fullWidth
            inputProps={{ maxLength: 40 }}
            label="Text"
            onChange={(e) => handleBubbleChange({ text: e.target.value })}
            sx={{ mb: 2 }}
            value={bubble.text}
          />
          <ColorPickerV2
            color={bubble.design.backgroundColor}
            defaultColor="#653ffe"
            label="Background Color"
            onChangeComplete={(color) =>
              handleBubbleChange({
                design: {
                  ...bubble.design,
                  backgroundColor: color || '#653ffe',
                },
              })
            }
          />
          <ColorPickerV2
            color={bubble.design.textColor}
            defaultColor="#ffffff"
            label="Text Color"
            onChangeComplete={(color) =>
              handleBubbleChange({
                design: {
                  ...bubble.design,
                  textColor: color || '#ffffff',
                },
              })
            }
          />
        </Box>
      )}
    </Box>
  );
};
