// ControllersView.tsx
import { useState, useCallback } from 'react';
import { Alert, Box, Button, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableViewToolbar from '../Common/TableViewToolbar';
import { LoadingSpinner } from '../Utils';
import { TestIds } from '../../test-utils';
import { useControllers } from '../../hooks/useControllers';
import { useControllerActions } from '../../hooks/useControllerActions';
import ControllerGrid from './ControllersGrid';

const ControllersView = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const handleError = useCallback((message: string) => {
    setError(message);
  }, []);

  const { controllers, loading, error: fetchError } = useControllers();
  const { handleEdit, handleRemove, handleCopyEmbed } = useControllerActions(handleError);

  const handleAddNew = useCallback(() => {
    navigate('/new-controller-wizard');
  }, [navigate]);

  return (
    <Box data-testid={TestIds.CONTROLLERS.CONTAINER} sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={isLargeScreen ? 'row' : 'column'}
        justifyContent="space-between"
        width="100%"
      >
        <TableViewToolbar title="Controllers" />
        <Button
          color="secondary"
          onClick={handleAddNew}
          sx={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            margin: isLargeScreen ? '0 10px' : '20px auto',
            textTransform: 'none',
            width: isLargeScreen ? 'auto' : '100%',
            maxHeight: '48px',
          }}
          variant="outlined"
        >
          New controller
        </Button>
      </Box>

      {loading && <LoadingSpinner />}

      {fetchError && (
        <Alert severity="error">
          Failed to load controllers. Please try again later or contact support.
        </Alert>
      )}

      {!loading && !fetchError && controllers.length === 0 && (
        <Box alignItems="center" display="flex" height="100%" justifyContent="center">
          <Typography>No controllers found. Add a new controller to get started.</Typography>
        </Box>
      )}

      {!loading && !fetchError && controllers.length > 0 && (
        <ControllerGrid
          controllers={controllers}
          onCopyEmbed={handleCopyEmbed}
          onEdit={handleEdit}
          onRemove={handleRemove}
        />
      )}

      <Snackbar autoHideDuration={5000} onClose={() => setError(null)} open={!!error}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default ControllersView;
