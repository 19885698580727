// src/components/Profile/components/SubscriptionDetails.tsx
import { Grid, Paper, Typography } from '@mui/material';
import {} from 'lucide-react';
import { format } from 'date-fns';
import { memo } from 'react';
import { SubscriptionDetailsProps } from '../types';
import { SubscriptionField } from './SubscriptionField';

export const SubscriptionDetails = memo(({ subscription }: SubscriptionDetailsProps) => (
  <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
    <Typography gutterBottom variant="h5">
      Current Subscription
    </Typography>
    {subscription ? (
      <Grid container spacing={2}>
        <SubscriptionField title="Plan" value={subscription.plan.name} />
        <SubscriptionField title="Status" value={subscription.status} />
        <SubscriptionField
          title="Start Date"
          value={format(new Date(subscription.startDate), 'PPP')}
        />
        <SubscriptionField title="End Date" value={format(new Date(subscription.endDate), 'PPP')} />
      </Grid>
    ) : (
      <Typography>No active subscription</Typography>
    )}
  </Paper>
));
