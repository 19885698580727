// src/components/CreateNewControllerWizardV2/steps/StyleStep/components/BorderConfig.tsx

import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useWizard } from '../../../context/WizardContext';
import ColorPickerV2 from '../../../../Common/ColorPickerV2';
import { useStyleUpdates } from '../hooks/useStyleUpdates';

export const BorderConfig: React.FC = () => {
  const { state } = useWizard();
  const { border } = state.styleConfig;
  const { updateStyle } = useStyleUpdates();

  const handleBorderChange = (changes: Partial<typeof border>) => {
    updateStyle(
      {
        border: { ...border, ...changes },
      },
      undefined,
      true,
    );
  };

  const handleGradientColorChange = (index: number) => (color: string) => {
    const currentColors = border.gradientColors || ['#00df81', '#653ffe'];
    const newColors = [...currentColors];
    newColors[index] = color;
    handleBorderChange({ gradientColors: newColors as [string, string] });
  };

  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Border
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={border.enabled}
            onChange={(e) => handleBorderChange({ enabled: e.target.checked })}
          />
        }
        label="Border Enabled"
      />
      {border.enabled && (
        <Box mt={2}>
          <ColorPickerV2
            color={border.gradientColors?.[0] || '#00df81'}
            defaultColor="#00df81"
            label="Gradient Start Color"
            onChangeComplete={handleGradientColorChange(0)}
          />
          <ColorPickerV2
            color={border.gradientColors?.[1] || '#653ffe'}
            defaultColor="#653ffe"
            label="Gradient End Color"
            onChangeComplete={handleGradientColorChange(1)}
          />
        </Box>
      )}
    </Box>
  );
};
