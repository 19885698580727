// src/components/CreateNewControllerWizardV2/steps/StyleStep/components/WidgetSizeConfig.tsx

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useWizard } from '../../../context/WizardContext';
import { useStyleUpdates } from '../hooks/useStyleUpdates';

export const WidgetSizeConfig: React.FC = () => {
  const { state } = useWizard();
  const { updateStyle } = useStyleUpdates();

  // const handleSizeChange = (size: 'S' | 'M' | 'L') => {
  //   updateStyle({ size });
  // };

  const handleSizeChange = (size: 'S' | 'M' | 'L') => {
    updateStyle(
      { widgetSize: size }, // Change this to match the API expectation
      undefined,
      true,
    );
  };

  return (
    <>
      <Typography gutterBottom variant="subtitle1">
        Widget Size
      </Typography>
      <FormControl fullWidth>
        <InputLabel>Size</InputLabel>
        <Select
          label="Size"
          onChange={(e) => handleSizeChange(e.target.value as 'S' | 'M' | 'L')}
          value={state.styleConfig.widgetSize}
        >
          <MenuItem value="S">Small</MenuItem>
          <MenuItem value="M">Medium</MenuItem>
          <MenuItem value="L">Large</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
