// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/index.tsx

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Grid, Paper, Stack } from '@mui/material';
import { WidgetPreview } from '../../shared/WidgetPreview';
import { ControllerBasicInfo } from './components/ControllerBasicInfo';
import { SchedulingConfig } from './components/SchedulingConfig';
import { ActionConfig } from './components/ActionConfig';
import { DomainConfig } from './components/DomainConfig';
import { useConfigurationUpdates } from './hooks/useConfigurationUpdates';
import { useWizard } from '../../context/WizardContext';
import { configureSchema } from './validation';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';

const ConfigureStep: React.FC = () => {
  const { goToNextStep } = useWizardNavigation();
  const { state, dispatch } = useWizard();
  const { updateConfiguration } = useConfigurationUpdates();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    setValue,
  } = useForm({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      name: state.configurationConfig?.name || '',
      action: {
        buttonLabel: state.configurationConfig?.action?.buttonLabel || '',
        disclaimerText: state.configurationConfig?.action?.disclaimerText || '',
      },
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit(onSubmit),
          isSubmitting,
          isValid,
        },
      },
    });

    // Cleanup when component unmounts
    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid, handleSubmit]);

  const onSubmit = async (data: { name: string }) => {
    try {
      await updateConfiguration(data, true); // Use immediate update
      goToNextStep(); // Navigate after successful update
    } catch (error) {
      // Handle error if needed TODO - Add error handling with snackbar?
      console.error('Failed to update configuration:', error);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit(onSubmit),
          isSubmitting,
          isValid,
        },
      },
    });
  }, [isSubmitting, isValid, handleSubmit]);

  return (
    <Grid container spacing={2}>
      {/* Form Column */}
      <Grid item md={6} xs={12}>
        <Stack spacing={3}>
          {/* Basic Info */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <ControllerBasicInfo errors={errors} register={register} />
          </Paper>

          {/* Scheduling */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <SchedulingConfig />
          </Paper>

          {/* Action Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <ActionConfig errors={errors} register={register} setValue={setValue} />
          </Paper>

          {/* Domain Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <DomainConfig />
          </Paper>
        </Stack>
      </Grid>

      {/* Preview Column */}
      <Grid item md={6} xs={12}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview fullscreenMode />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ConfigureStep;
