// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/ActionConfig.tsx

import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { useWizard } from '../../../context/WizardContext';
import { useConfigurationUpdates } from '../hooks/useConfigurationUpdates';
import { ConfigureFormValues } from '../validation';
import { TextInput } from '../../../../Inputs';

export const ActionConfig: React.FC<{
  register: UseFormRegister<ConfigureFormValues>;
  errors: FieldErrors<ConfigureFormValues>;
  setValue: UseFormSetValue<ConfigureFormValues>;
}> = ({ register, errors, setValue }) => {
  const { updateConfiguration } = useConfigurationUpdates();
  const { state } = useWizard();
  const { action } = state.configurationConfig || {};

  const handleFieldChange = (field: 'buttonLabel' | 'disclaimerText', value: string) => {
    setValue(`action.${field}`, value, {
      shouldValidate: true,
      shouldDirty: true,
    });

    if (!errors.action?.[field]) {
      updateConfiguration(
        {
          action: {
            ...action,
            [field]: value,
          },
        },
        false,
      );
    }
  };

  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Action Settings
      </Typography>
      <Stack spacing={2}>
        <TextInput
          {...register('action.buttonLabel')}
          errors={errors}
          fullWidth
          label="Button Label"
          name="action.buttonLabel"
          onChange={(e) => handleFieldChange('buttonLabel', e.target.value)}
        />
        <TextInput
          {...register('action.disclaimerText')}
          errors={errors}
          fullWidth
          label="Disclaimer Text"
          name="action.disclaimerText"
          onChange={(e) => handleFieldChange('disclaimerText', e.target.value)}
        />
      </Stack>
    </Box>
  );
};
