// src/components/Profile/ProfileDisplay.tsx

import React, { memo } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { LoadingSpinner } from '../Utils';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import { TestIds } from '../../test-utils';
import { ProfileDisplayProps } from './types';
import { SubscriptionDetails } from './compnents/SubscriptionDetails';
import { ProfileInfo } from './compnents/ProfileInfo';
import { SubscriptionHistory } from './compnents/SubscriptionHistory';
import { PaymentSection } from './compnents/PaymentSection';

const ProfileDisplay = memo(
  ({ customer, isLoading, onUpdatePayment, error }: ProfileDisplayProps) => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!customer) {
      return <Typography>No customer data available</Typography>;
    }

    return (
      <Container data-testid={TestIds.PROFILE.CONTAINER} maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography gutterBottom variant="h4">
          My Account
        </Typography>

        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <ProfileInfo customer={customer} />
          </Grid>

          <Grid item md={8} xs={12}>
            <SubscriptionDetails subscription={customer.currentSubscription} />
            <SubscriptionHistory subscriptionHistory={customer.subscriptionHistory} />
          </Grid>
        </Grid>

        <Box mt={3}>
          <PaymentSection onUpdatePayment={onUpdatePayment} />
        </Box>

        <ErrorSnackbar
          message={error || ''}
          onClose={() => {}} // Handle if needed
          open={!!error}
        />
      </Container>
    );
  },
);

export default ProfileDisplay;
