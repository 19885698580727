// src/components/Profile/components/SubscriptionHistory.tsx
import {
  Paper,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { format } from 'date-fns';
import { memo } from 'react';
import { TestIds } from '../../../test-utils';
import { SubscriptionHistoryProps } from '../types';

export const SubscriptionHistory = memo(({ subscriptionHistory }: SubscriptionHistoryProps) => (
  <Paper elevation={3} sx={{ p: 3 }}>
    <Typography gutterBottom variant="h5">
      Subscription History
    </Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Plan</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionHistory?.map((subscription, index) => (
            <TableRow key={index} data-testid={TestIds.PROFILE.HISTORY_ITEM}>
              <TableCell>{subscription.plan.name}</TableCell>
              <TableCell>{subscription.status}</TableCell>
              <TableCell>{format(new Date(subscription.startDate), 'PPP')}</TableCell>
              <TableCell>{format(new Date(subscription.endDate), 'PPP')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
));
